import { DialogService }                           from 'aurelia-dialog';
import { bindable, inject }                        from 'aurelia-framework';
import { PLATFORM }                                from 'aurelia-pal';
import { BaseListViewModel }                       from 'base-list-view-model';
import { CreateLotInterventionManageStudyModal }   from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-manage-studies/create';
import { EditLotInterventionManageStudyModal }     from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-manage-studies/edit';
import { FilterFormSchema }                        from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-manage-studies/filter-form-schema';
import { ViewLotInterventionManageStudyModal }     from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-manage-studies/view';
import { AppContainer }                            from 'resources/services/app-container';
import { LotInterventionsManageStudiesRepository } from './services/repository';

@inject(AppContainer, LotInterventionsManageStudiesRepository, DialogService, FilterFormSchema)
export class ListLotInterventionsManageStudies extends BaseListViewModel {

    listingId = 'management-manage-studies-lot-interventions-manage-studies-listing';

    @bindable headerTitle    = 'listing.management.manage-studies.manage-studies';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, repository, dialogService, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogService    = dialogService;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/simple-list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.concessions.concessions.manage',
            'management.concessions.concessions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.lotIntervention = params.model;
        this.readonly        = params.readonly;

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:            {
                action:  (row) => this.openModal(row, ViewLotInterventionManageStudyModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.view']),
            },
            edit:            {
                action:  (row) => this.openModal(row, EditLotInterventionManageStudyModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.edit']),
            },
            destroy:         !this.readonly && this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.delete']),
            destroySelected: !this.readonly,
            buttons:         [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly,
                    action:           () => this.openModal(this, CreateLotInterventionManageStudyModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      !this.readonly,
            sorting:         {
                column:    1,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'manage_study',
                    name:  'manage_studies.specification',
                    title: 'form.field.specification',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'lot_intervention_manage_studies.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:            'start_date',
                    name:            'lot_intervention_manage_studies.start_date',
                    title:           'form.field.start-date',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:            'finish_date',
                    name:            'lot_intervention_manage_studies.finish_date',
                    title:           'form.field.finish-date',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search(this.lotIntervention.id, criteria);
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
